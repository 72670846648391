var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "feather",
      style: _vm.rotationStyles,
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 8 13",
        fill: "none",
        stroke: "currentColor",
        width: _vm.width,
        height: _vm.height,
        color: _vm.color,
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M6.5 1L1 6.5L6.5 12",
          stroke: "currentColor",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }