<template>
  <div class="avatar-placeholer-wrapper" :style="avatarStyles">
    <vs-avatar v-if="modifiedPhotoURL" :src="modifiedPhotoURL" :size="size" />

    <div
      v-else
      class="avatar-placeholer"
      :style="{
        width: size,
        minWidth: size,
        height: size,
        minHeight: size,
        fontSize: fontSize
      }"
      v-html="avatarPlaceholer"
    />
  </div>
</template>

<script>
export default {
  props: {
    photoURL: {
      type: String,
      required: false
    },

    name: {
      type: String,
      required: false,
      default: null
    },

    size: {
      type: String,
      required: true
    },

    fontSize: {
      type: String,
      required: false,
      default: '18px'
    },

    isRounded: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  computed: {
    avatarStyles() {
      return {
        fontSize: this.fontSize,
        '--avatar-border': this.isRounded ? '50%' : '6px'
      }
    },

    modifiedPhotoURL() {
      return !this.photoURL || ['/user.png', '/logo.svg'].includes(this.photoURL) ? '' : this.photoURL
    },

    avatarPlaceholer() {
      if (!this.name) {
        return ''
      }

      const fullName = this.name.split(' ')
      let initials = ''

      if (fullName[0]) {
        initials = fullName[0].charAt(0)
      }

      if (fullName[1]) {
        initials = `${initials}${fullName[1].charAt(0)}`
      }

      return initials.toUpperCase()
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-placeholer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #3034f7;
  font-family: 'Lato';
  font-weight: 700;
  line-height: 0;
  background: #edf6fc;
  border-radius: var(--avatar-border) !important;
  overflow: hidden;

  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .con-vs-avatar {
      margin: 0;
      border-radius: var(--avatar-border) !important;
    }
  }
}
</style>
