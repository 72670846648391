<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 8 13"
    fill="none"
    stroke="currentColor"
    class="feather"
    :width="width"
    :height="height"
    :color="color"
    :style="rotationStyles"
  >
    <path d="M6.5 1L1 6.5L6.5 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'UserIcon',

  props: {
    color: {
      type: String,
      default: '#fff',
    },

    width: {
      type: Number,
      default: 11,
    },

    height: {
      type: Number,
      default: 11,
    },

    direction: {
      type: String,
      default: 'left',
    },
  },

  computed: {
    rotationStyles () {
      let angle = 0

      switch (this.direction) {
        case 'top':
          angle = 90

          break
        case 'bottom':
          angle = 270

          break
        case 'left':
          angle = 0

          break
        case 'right':
          angle = 180

          break
      }

      return `transform: rotate(${angle}deg);`
    },
  }
}
</script>
