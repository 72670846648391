var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "avatar-placeholer-wrapper", style: _vm.avatarStyles },
    [
      _vm.modifiedPhotoURL
        ? _c("vs-avatar", {
            attrs: { src: _vm.modifiedPhotoURL, size: _vm.size },
          })
        : _c("div", {
            staticClass: "avatar-placeholer",
            style: {
              width: _vm.size,
              minWidth: _vm.size,
              height: _vm.size,
              minHeight: _vm.size,
              fontSize: _vm.fontSize,
            },
            domProps: { innerHTML: _vm._s(_vm.avatarPlaceholer) },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }